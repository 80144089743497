import { useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Button,
  Divider,
  Grid,
  Icon,
  IconButton,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useClipboard,
  VStack,
} from '@chakra-ui/react';

import { useVideosAll, VideoList } from '@arena-labs/strive2-content';
import {
  CheckIcon,
  ClipboardCheckIcon,
  toast,
  VideoIcon,
  WaitForQuery,
} from '@arena-labs/strive2-ui';

import { $API } from '../../lib/api';

export function MediaPicker() {
  return (
    <Tabs>
      <TabList>
        <Tab>Playlists</Tab>
        <Tab>Videos</Tab>
      </TabList>

      <TabPanels>
        <TabPanel px="0">
          <MediaPlaylists />
        </TabPanel>
        <TabPanel px="0">
          <MediaVideos />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export type MediaPickerModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

MediaPicker.Modal = function MediaPickerModal({
  isOpen,
  onClose,
  ...props
}: MediaPickerModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontSize="h2">Media Picker</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <MediaPicker />
        </ModalBody>

        <ModalFooter>
          <Button size="sm" variant="primary" mr={3} onClick={onClose}>
            Close
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

function MediaVideos() {
  return (
    <VideoList
      section="VIDEOS"
      getHref={(video) => getVideoUrl(video.slug)}
      linkTarget="_blank"
      heading="Videos"
      useSectionVideos={useVideosAll}
      variant="list"
    />
  );
}

function MediaPlaylists() {
  const [currentPlaylist, setCurrentPlaylist] = useState<string>();
  const playlistQuery = $API.useGetPlaylists();
  const playlistVideosQuery = $API.useGetPlaylist(
    { params: { slug: currentPlaylist ?? '' } },
    { enabled: !!currentPlaylist },
  );

  return (
    <VStack spacing="2" align="stretch">
      <WaitForQuery
        query={playlistQuery}
        fallbackErrorMessage="Error loading playlists"
      >
        {(playlists) => (
          <Accordion
            allowToggle
            display="flex"
            flexDirection="column"
            gap="2"
            onChange={(val) =>
              setCurrentPlaylist(playlists.groups[Number(val)]?.slug)
            }
          >
            {playlists.groups.map((p, index) => (
              <AccordionItem
                key={p.slug}
                border="none"
                borderRadius="card"
                bg={`strive.pillar.${(index % 6) + 1}`}
              >
                <AccordionButton minHeight="64px">
                  <Text align="left" fontWeight="bold">
                    {p.title}
                  </Text>
                  <AccordionIcon ml="auto" />
                </AccordionButton>
                <AccordionPanel borderBottomRadius="card" bg="gray.700">
                  <WaitForQuery
                    query={playlistVideosQuery}
                    loading="Loading videos..."
                  >
                    {(playlist) => {
                      const playlistUrl = getPlaylistUrl(playlist.slug);
                      return (
                        <VStack spacing="1">
                          <Grid templateColumns="1fr auto" w="full">
                            <Link
                              variant="underline"
                              href={playlistUrl}
                              target="_blank"
                            >
                              Open Playlist
                            </Link>
                            <CopyButton
                              url={playlistUrl}
                              label={playlist.title}
                            />
                          </Grid>
                          <Divider />
                          <Text alignSelf="start" fontWeight="bold">
                            Videos:
                          </Text>
                          {playlist.videos.map((v) => (
                            <Grid
                              key={v.slug}
                              templateColumns="auto 1fr auto"
                              gap="2"
                              w="full"
                            >
                              <Icon as={VideoIcon} mt="1" />
                              <Link
                                variant="underline"
                                href={getVideoUrl(v.slug)}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {v.title}
                              </Link>
                              <CopyButton
                                url={getVideoUrl(v.slug)}
                                label={v.title}
                              />
                            </Grid>
                          ))}
                        </VStack>
                      );
                    }}
                  </WaitForQuery>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        )}
      </WaitForQuery>
    </VStack>
  );
}

function getVideoUrl(slug: string) {
  return `${process.env.NEXT_PUBLIC_STRIVE_HOST}/content/videos/${slug}`;
}

function getPlaylistUrl(slug: string) {
  return `${process.env.NEXT_PUBLIC_STRIVE_HOST}/content/playlists/${slug}`;
}

function CopyButton({ url, label }: { url: string; label?: string }) {
  const clipboard = useClipboard(url);
  const copyUrl = (url: string) => {
    clipboard.onCopy();
    toast({ status: 'success', description: `Copied URL` });
  };

  return (
    <IconButton
      aria-label={`Copy URL: ${label ?? url}`}
      onClick={() => copyUrl(url)}
      variant="unstyled"
      borderRadius="50%"
      display="grid"
      placeItems="center"
      bg={clipboard.hasCopied ? 'green.800' : 'gray.500'}
    >
      <Icon
        as={clipboard.hasCopied ? CheckIcon : ClipboardCheckIcon}
        size="5"
      />
    </IconButton>
  );
}
