import React from 'react';
import { useSession } from 'next-auth/react';

import { UserProfile } from '@arena-labs/shared-models';

export type RestrictProps = {
  to: UserProfile['profile']['roles'];
  denied?: React.ReactNode;
  children: React.ReactNode;
};

export function Restrict({ to, denied, children }: RestrictProps) {
  const { data: session } = useSession();
  if (!session) return null;

  const isAllowed = to.some((i) => session.user.profile.roles.includes(i));

  if (!isAllowed) {
    return denied;
  }

  return children;
}
