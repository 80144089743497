import { CSSProperties } from 'react';
import { useMeasure } from 'react-use';
import { Box, Grid } from '@chakra-ui/react';

import { Header } from './header';

export type CoachPortalLayoutProps = {
  children: React.ReactNode;
};

export function CoachPortalLayout({ children }: CoachPortalLayoutProps) {
  const [ref, { height }] = useMeasure<HTMLDivElement>();
  return (
    <Grid
      height="100%"
      sx={{
        grid: `
              "banner" auto
              "main" 1fr
              "footer" auto
            `,
      }}
      style={
        {
          '--header-height': `${height}px`,
        } as CSSProperties
      }
    >
      <Box ref={ref} zIndex={1}>
        <Header />
      </Box>
      <Box gridArea="main" h="full" maxH="full" minW="100%" zIndex={0}>
        {children}
      </Box>
    </Grid>
  );
}
