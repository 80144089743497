import NextLink from 'next/link';
import { Button, Flex, Link, Text, useDisclosure } from '@chakra-ui/react';
import { useSession } from 'next-auth/react';

import { BrandLogo } from '@arena-labs/strive2-ui';

import { CoachPortalMenu } from './menu';
import { SignInModal } from './signin-form';

export function Header() {
  const { data: session, status } = useSession();
  const signInModal = useDisclosure();

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      p={4}
      h={16}
      bg="brand.bgDark"
      borderBottom="1px solid"
      borderColor="gray.200"
      gridArea="banner"
    >
      <Link as={NextLink} href="/" display="flex" gap="4" alignItems="center">
        <BrandLogo iconSize={10} noText />
        <Text>Strive Dashboard</Text>
      </Link>
      {status === 'authenticated' ? (
        <Flex gap={4}>
          <CoachPortalMenu user={session.user} />
        </Flex>
      ) : status === 'unauthenticated' ? (
        <>
          <SignInModal {...signInModal} />
          <Button
            variant="solid"
            colorScheme="brand"
            onClick={signInModal.onOpen}
          >
            Sign in
          </Button>
        </>
      ) : null}
    </Flex>
  );
}
