import { useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Flex,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';

import { toast } from '@arena-labs/strive2-ui';

import { $API } from '../../lib/api';

export function SendMessages({
  messageType,
  modalOnClose,
}: {
  messageType: 'coachportal' | 'launchpad';
  modalOnClose: () => void;
}) {
  const [checked, setChecked] = useState(false);

  let link = '';
  if (messageType === 'coachportal') {
    link =
      'https://docs.google.com/spreadsheets/d/1u4WEciQZFpFxTsGWCncSCn7N0-36KEtsIOD3H0qjOdk/edit?usp=sharing';
  } else {
    link =
      'https://docs.google.com/spreadsheets/d/1dQnsVxQYvlidrKyroCoYIH3CTGQ9fmhjCeO-gxAKkog/edit?usp=sharing';
  }

  const sendAutomatedMessages = $API.useSendAutomatedMessages(undefined, {
    onSuccess: () => {
      toast({
        title: 'Success',
        description: 'Automated Messages Sent Successfully!',
        status: 'success',
        isClosable: true,
      });
      modalOnClose();
    },
    onError: () => {
      toast({
        title: 'Error',
        description: 'Error Sending Automated Messages, try again!',
        status: 'error',
        isClosable: true,
      });
    },
  });
  return (
    <Flex direction="column" gap={4}>
      <Text fontSize="h3">
        Are you sure you want to send automated {messageType} messages to all
        clinicians?
      </Text>
      <Divider />
      <Flex gap={4} alignItems="center">
        <Checkbox
          checked={checked}
          onChange={(e) => setChecked(e.target.checked)}
        />
        <Text fontSize="h4">
          Yes I have checked the{' '}
          <Link href={link} target="_blank" color="red">
            google sheet
          </Link>{' '}
          and it&apos;s ready to send.
        </Text>
      </Flex>
      <Button
        disabled={!checked}
        isLoading={sendAutomatedMessages.isLoading}
        onClick={() => sendAutomatedMessages.mutate({ type: messageType })}
        colorScheme="red"
        variant="primary"
        size="lg"
      >
        Send messages
      </Button>
    </Flex>
  );
}

export type SendMessagesModalProps = {
  isOpen: boolean;
  onClose: () => void;
  messageType: 'coachportal' | 'launchpad';
};

SendMessages.Modal = function SendMessagesModal({
  isOpen,
  onClose,
  messageType,
  ...props
}: SendMessagesModalProps) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text fontSize="h2">Send automated messages</Text>
          <ModalCloseButton />
        </ModalHeader>
        <ModalBody>
          <SendMessages messageType={messageType} modalOnClose={onClose} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
