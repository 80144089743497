import { useForm } from 'react-hook-form';
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { signIn as _signIn } from 'next-auth/react';
import { z } from 'zod';

import { AlertBox, Title } from '@arena-labs/strive2-ui';

type FormValues = z.infer<typeof schema>;
const schema = z.object({
  username: z.string().min(3),
  password: z.string().min(8),
});

export function useSignIn({ onSuccess }: { onSuccess?: () => void } = {}) {
  return useMutation(
    async (data: FormValues) => {
      const result = await _signIn('credentials', { ...data, redirect: false });
      if (!result?.ok) {
        throw new Error(result?.error || 'An error occurred');
      }
      return result;
    },
    {
      onSuccess,
    },
  );
}

export type SignInFormProps = {
  id?: string;
  onSuccess?: () => void;
  onError?: (error: unknown) => void;
  onSubmit: (data: FormValues) => Promise<unknown>;
};

export function SignInForm({ id = 'signin-form', ...props }: SignInFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    resolver: zodResolver(schema),
  });

  const onSubmit = handleSubmit(async (data) => {
    try {
      await props.onSubmit(data);
      props.onSuccess?.();
    } catch (error) {
      props?.onError?.(error);
    }
  });

  return (
    <form id={id} onSubmit={onSubmit}>
      <VStack spacing="4" align="stretch">
        <FormControl
          id={`${id}-username`}
          isInvalid={Boolean(errors?.username)}
        >
          <FormLabel fontSize="sm" fontWeight="bold" mb="1">
            Username
          </FormLabel>
          <Input inputMode="email" {...register('username')} />
          <FormErrorMessage>{errors.username?.message}</FormErrorMessage>
        </FormControl>
        <FormControl
          id={`${id}-password`}
          isInvalid={Boolean(errors?.password)}
        >
          <FormLabel fontSize="sm" fontWeight="bold" mb="1">
            Password
          </FormLabel>
          <Input type="password" {...register('password')} />
          <FormErrorMessage>{errors.password?.message}</FormErrorMessage>
        </FormControl>
      </VStack>
    </form>
  );
}

export type SignInModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
};

export function SignInModal(props: SignInModalProps) {
  const signIn = useSignIn({
    onSuccess: () => {
      props.onClose();
      props.onSuccess?.();
    },
  });
  const formId = 'signin-form';
  return (
    <Modal isCentered isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Title pt="1" fontSize="h3">
            Sign in
          </Title>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing="4" align="stretch">
            <SignInForm id={formId} onSubmit={signIn.mutateAsync} />
            {signIn.isError && (
              <AlertBox
                status="error"
                title="Error"
                description={
                  signIn.error instanceof Error ? (
                    <Text noOfLines={2}>{signIn.error.message}</Text>
                  ) : (
                    'An error occurred'
                  )
                }
              />
            )}
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Button
            type="submit"
            form={formId}
            isLoading={signIn.isLoading}
            variant="solid"
            w="full"
          >
            Sign In
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
