import { useState } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import {
  Avatar,
  Button,
  HStack,
  Icon,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { signOut, useSession } from 'next-auth/react';

import { UserProfile } from '@arena-labs/shared-models';
import {
  ChevronRightIcon,
  ClipboardCheckIcon,
  CommunicationSkillIcon,
  HeadsetIcon,
  SettingsIcon,
  SignOutIcon,
  VideosIcon,
} from '@arena-labs/strive2-ui';

import { SendMessages } from '../automation/send-messages';
import { MediaPicker } from '../media-library/media-picker';
import { Restrict } from './restrict';
import { useDebugActions, useDebugState } from './use-debug-state';

export type CoachPortalMenuProps = {
  user: UserProfile;
};

export function CoachPortalMenu({ user }: CoachPortalMenuProps) {
  const { data: session } = useSession();
  const router = useRouter();
  const currentPage = router.pathname;

  const { isEnabled: isDebugEnabled } = useDebugState();
  const { toggle: toggleDebug } = useDebugActions();
  const mediaPickerModal = useDisclosure();
  const queryClient = useQueryClient();

  const automatedMessageModel = useDisclosure();
  const [messageType, setMessageType] = useState<'coachportal' | 'launchpad'>(
    'coachportal',
  );

  const buttonStyles = {
    bg: 'gray.700',
    _hover: { bg: 'brand.800' },
    sx: {
      '&[aria-current=page]': {
        fontWeight: 'bold',
        bg: 'gray.800',
      },
    },
  };

  if (!session) return null;

  return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          variant="solid"
          rightIcon={
            <Icon as={ChevronRightIcon} transform="rotate(90deg)" boxSize="2" />
          }
          alignItems="center"
        >
          <HStack>
            <Avatar size="xs" />
            <Text>
              {user.first_name} {user.last_name}
            </Text>
          </HStack>
        </MenuButton>
        <MenuList bg="transparent">
          {user.is_staff ? (
            <MenuItem onClick={() => toggleDebug()} {...buttonStyles} gap="4">
              <Icon as={SettingsIcon} /> Debug (
              {isDebugEnabled ? 'disable' : 'enable'})
            </MenuItem>
          ) : null}
          <Restrict to={['leader']}>
            <Link href="/leader" passHref legacyBehavior>
              <MenuItem
                as="a"
                aria-current={currentPage === '/leader' ? 'page' : undefined}
                gap="4"
                {...buttonStyles}
              >
                <Icon as={ClipboardCheckIcon} /> Leader
              </MenuItem>
            </Link>
          </Restrict>
          <Restrict to={['coach']}>
            <Link href="/coach" passHref legacyBehavior>
              <MenuItem
                as="a"
                aria-current={currentPage === '/coach' ? 'page' : undefined}
                gap="4"
                {...buttonStyles}
              >
                <Icon as={CommunicationSkillIcon} /> Coach
              </MenuItem>
            </Link>
            <MenuItem
              {...buttonStyles}
              gap="4"
              onClick={mediaPickerModal.onOpen}
            >
              <Icon as={VideosIcon} /> Media Picker
            </MenuItem>
          </Restrict>
          <Restrict to={['launchpad_guide']}>
            <Link href="/launchpad" passHref legacyBehavior>
              <MenuItem
                as="a"
                aria-current={currentPage === '/launchpad' ? 'page' : undefined}
                gap="4"
                {...buttonStyles}
              >
                <Icon as={HeadsetIcon} /> Launchpad Admin
              </MenuItem>
            </Link>
          </Restrict>
          <MenuItem
            onClick={() => {
              signOut({ redirect: false });
              queryClient.cancelQueries();
              queryClient.removeQueries();
              queryClient.invalidateQueries();
            }}
            {...buttonStyles}
            gap="4"
          >
            <Icon as={SignOutIcon} /> Sign out
          </MenuItem>
          <MenuDivider />
          <Restrict to={['leader']}>
            <MenuItem
              as="a"
              gap="4"
              {...buttonStyles}
              onClick={() => {
                automatedMessageModel.onOpen();
                setMessageType('launchpad');
              }}
            >
              <Icon as={ClipboardCheckIcon} /> Send Launchpad Messages
            </MenuItem>
          </Restrict>
          <Restrict to={['coach']}>
            <MenuItem
              as="a"
              gap="4"
              {...buttonStyles}
              onClick={() => {
                automatedMessageModel.onOpen();
                setMessageType('coachportal');
              }}
            >
              <Icon as={CommunicationSkillIcon} /> Send Coachportal Messages
            </MenuItem>
          </Restrict>
        </MenuList>
      </Menu>
      <MediaPicker.Modal {...mediaPickerModal} />
      <SendMessages.Modal
        {...automatedMessageModel}
        messageType={messageType}
      />
    </>
  );
}
