import { useEffect } from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalProps,
} from '@chakra-ui/react';

import {
  AnalyticsContext,
  ContentTrackingTag,
  MediaTrackingEvent,
  useAnalytics,
} from '@arena-labs/analytics';
import { PlayableVideo, VideoListItem } from '@arena-labs/shared-models';
import { durationToMs } from '@arena-labs/strive2-ui';

import { VerticalVideo } from '../vertical-video';
import { useVideoViewMutation } from './media-played';
import {
  useInitialMediaStartTime,
  useMediaProgressHandler,
} from './media-progress-store';
import { getVideoUrls } from './media-url';
import { VideoPlayerProps } from './video-player';

export type VideoModalProps = {
  video: PlayableVideo;
  onPlayerClosed?: (percent?: number) => void;
  title?: string;
  relatedVideos?: VideoListItem[];
  onEnded?: VideoPlayerProps['onEnded'];
  onClose: () => void;
  description?: string;
} & Omit<VideoPlayerProps, 'videoUrl' | 'thumbnailUrl'> &
  Omit<ModalProps, 'children' | 'orientation'>;

export function VideoModal({
  video,
  orientation = 'portrait',
  onPlayerClosed,
  onEnded,
  title,
  relatedVideos = [],
  isOpen,
  onClose,
  description,
  ...props
}: VideoModalProps) {
  const { mutateAsync: createVideoViewMutation } = useVideoViewMutation();

  const rememberProgress = useMediaProgressHandler(video);
  const [startTime, setStartTime] = useInitialMediaStartTime(video, {
    staleTime: durationToMs({ days: 1 }),
  });

  const { videoUrl, imageUrl } = getVideoUrls(video, { startTime });

  const analytics = useAnalytics({
    tags: [ContentTrackingTag.StandaloneVideo],
    videoSlug: video.slug,
  });

  useEffect(() => {
    if (isOpen && video.slug) {
      analytics.logEvent(MediaTrackingEvent.VideoModalOpen);
    }
  }, [analytics, isOpen, video.slug]);

  if (!video.slug) {
    return null;
  }

  const handleClose = (percent: number) => {
    if (percent > 66) {
      createVideoViewMutation([video.slug, percent]);
    }
    onClose();
  };

  return (
    <AnalyticsContext context={analytics.context}>
      <Modal size="full" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          bg="modal.bg"
          m={0}
          p={0}
          pt="env(safe-area-inset-top)"
          maxHeight="full"
          zIndex={0}
        >
          <ModalBody m={0} px={2} pb={4} maxH="full" rounded={'card'}>
            <VerticalVideo
              objectFit={'contain'}
              videoUrl={videoUrl}
              thumbnailUrl={imageUrl}
              onEnded={() => {
                createVideoViewMutation([video.slug, 100]);
              }}
              onClose={handleClose}
              onLoadedMetadata={setStartTime}
              onTimeUpdate={rememberProgress}
              showCloseButton
              title={title}
              description={description}
              autoPlay
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </AnalyticsContext>
  );
}
